import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 * @param  {String} landingApplicationId
 */
export class VeryfiCompDom extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
    this._comprobanteDomicilio = ''
    this._selfi_domicilio = ''
  }

  setComprobante(Comprobante) {
    this._comprobanteDomicilio = Comprobante
  }

  setSelfiDomicilio(Selfi) {
    this._selfi_domicilio = Selfi
  }

  get data() {
    // en este caso no se envia un object se envia como multioart/form-data
    const formData = new FormData();
    // en la linea 24 se agrega al multipart el file del signature, el primer parametro es la key del service y el segundo su valor
    formData.append('ProofOfAddress', this._comprobanteDomicilio)
    // en la linea 26 se agrega al multipart el credit aplicationId, el primer parametro es la key del service y el segundo su valor
    formData.append('CreditApplicationId', this._creditApplicationId)
    // retornamos ala peticion el multipart creado
    return formData
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'POST',
      url: '/verifications/address-autonomation',
      data: self.data
    }))
  }
}
